import { useRouter } from 'next/router'
import { useEffect, useContext } from 'react'
import axios from 'axios'
import { UserContext } from 'context/UserContext'

export default function Index() {
  const router = useRouter()
  const { isLogged } = useContext(UserContext)

  useEffect(() => {
    axios.defaults.baseURL = process.env.ENGLISH_BACKEND_URL

    if (isLogged()) {
      router.push('/home')
    } else {
      router.push('/join')
    }
  }, [])

  return <></>
}
